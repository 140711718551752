<template>
  <div>
    <div class="content" @click="showtype = false">
      <div class="all" style="position: relative">
        <div class="SearchBar" ref="viewBox">
          <!--搜索框-->
          <el-form ref="search" :inline="true" :model="search" size="small">
            <el-form-item label="第三方施工队" class="lab">
              <el-input
                v-model="search.teamName"
                placeholder="请输入"
                class="SearchInput"
                clearable
              ></el-input>
            </el-form-item>
            <div ref="viewContract" style="display: inline-block">
              <el-form-item label="合同类型" class="lab viewContract">
                <el-input
                  @click.native.stop="showtype = true"
                  v-model="search.type"
                  placeholder="请选择"
                  class="SearchInput"
                  clearable
                  @clear="clearType"
                  readonly
                >
                  <template v-slot:suffix @click.stop="clearType">
                    <i
                      @click.stop="clearType"
                      :class="
                        search.type
                          ? 'el-icon-circle-close'
                          : 'el-icon-caret-bottom'
                      "
                    ></i>
                  </template>
                </el-input>
                <div
                  class="searchType"
                  :style="style"
                  @click.stop="showtype = true"
                  v-if="showtype"
                >
                  <div style="margin: 10px">
                    <el-radio-group
                      v-model="search.contractTypeId"
                      @change="changeChildSearch"
                    >
                      <div v-for="item in options" :key="item.id">
                        <el-radio
                          class="radioList"
                          :label="item.id"
                          :key="item.id"
                          >{{ item.name }}</el-radio
                        >
                        <el-checkbox-group
                          @change="changeLabelSearch"
                          v-model="searchContractTypeChildIdArr"
                          style="margin-left: 20px"
                          :disabled="isCheckBokS == 1 ? true : false"
                          v-if="item.children && item.children.length > 0"
                        >
                          <el-checkbox
                            v-for="itemc in item.children"
                            :label="itemc.id"
                            :key="itemc.id"
                            style="width: 140px"
                            >{{ itemc.name }}</el-checkbox
                          >
                        </el-checkbox-group>
                      </div>
                    </el-radio-group>
                  </div>
                </div>
              </el-form-item>
            </div>

            <el-form-item label="状态" class="lab">
              <el-select
                v-model="search.applyStatus"
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in statusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="申请时间" class="lab">
              <el-date-picker
                v-model="searchDatatime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                clearable
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="申请单号" class="lab" label-width="95px">
              <el-input
                v-model="search.applyCode"
                placeholder="请输入"
                class="SearchInput"
                clearable
              ></el-input>
            </el-form-item>
            <span>
              <el-button
                type="primary"
                class="SearchBtn"
                size="small"
                @click="getList(1)"
                >查询</el-button
              >
              <el-button
                type="primary"
                plain
                class="SearchBtn2"
                size="small"
                @click="reset"
                >重置</el-button
              >
            </span>
          </el-form>
        </div>
        <div class="table">
          <!--数据表格-->
          <el-table
            ref="tableData"
            :data="tableData"
            style="width: 100%; margin: 15px 0"
            border
            height="calc(100vh - 402px)"
            :stripe="true"
            @sort-change="sort_change"
            :default-sort="{ prop: 'applyTime', order: 'descending' }"
          >
            <el-table-column
              type="index"
              label="序号"
              width="70"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="applyCode"
              label="申请单号"
              align="center"
              show-overflow-tooltip
              width="180"
            >
            </el-table-column>
            <el-table-column
              prop="teamName"
              label="第三方施工队"
              align="center"
              show-overflow-tooltip
              width="250"
            >
            </el-table-column>
            <el-table-column
              prop="contractTypeName"
              label="申请合同类型"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="applyUser"
              label="申请人"
              align="center"
              show-overflow-tooltip
              width="180"
            >
            </el-table-column>
            <el-table-column
              prop="applyTime"
              label="申请时间"
              align="center"
              show-overflow-tooltip
              sortable="custom"
              width="200"
            >
            </el-table-column>
            <el-table-column
              prop="applyStatusName"
              label="状态"
              align="center"
              show-overflow-tooltip
              width="120"
            >
              <template slot-scope="{ row }">
                <el-tag
                  v-if="row.applyStatus == 1"
                  type="success"
                  size="small"
                  plain
                  >{{ row.applyStatusName }}</el-tag
                >
                <el-tag
                  v-if="row.applyStatus == 2"
                  type="primary"
                  size="small"
                  plain
                  >{{ row.applyStatusName }}</el-tag
                >
                <el-tag
                  v-if="row.applyStatus == 3"
                  type="warning"
                  size="small"
                  plain
                  >{{ row.applyStatusName }}</el-tag
                >
                <el-tag
                  v-if="row.applyStatus == 4"
                  type="danger"
                  size="small"
                  plain
                  >{{ row.applyStatusName }}</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="operation"
              label="操作"
              align="left"
              width="200"
            >
              <template slot-scope="{ row }">
                <el-button
                  v-if="row.applyStatus == 3 || row.applyStatus == 4"
                  type="primary"
                  size="small"
                  @click="desc(row)"
                  >查看</el-button
                >
                <el-button
                  v-if="row.applyStatus == 2"
                  type="warning"
                  size="small"
                  @click="apply(row)"
                  >审核</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="pageBox">
            <el-pagination
              :current-page="search.current"
              :background="true"
              :page-sizes="[30, 50, 100]"
              :page-size="search.size"
              layout="total, prev, pager, next, sizes, jumper"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      title="资质申请审批"
      :visible.sync="dialogVisibleDesc"
      width="30%"
      :close-on-click-modal="false"
      @close="clearnF('form')"
    >
      <el-form ref="addform" :model="form" label-width="120px" size="mini">
        <div>
          <el-form-item label="申请单号：" prop="positionName">
            {{ form.applyCode }}
          </el-form-item>
          <el-form-item label="施工队：" prop="positionName">
            {{ form.teamName }}
          </el-form-item>
          <el-form-item label="签合同类型：" prop="positionCode">
            {{ form.contractTypeName }}
          </el-form-item>
          <el-form-item label=" 施工资质+合同样本:" prop="positionCode">
            <el-image
              style="width: 100px; height: 100px"
              :src="form.urlList[0]"
              :preview-src-list="form.urlList"
            >
            </el-image>
            <span style="margin-left: 10px">共{{ form.urlList.length }}张</span>
          </el-form-item>
          <el-form-item label="施工说明:" prop="positionCode">
            <span style="word-break: break-all">{{ form.note }}</span>
          </el-form-item>
          <el-form-item label="申请人:">
            <span style="word-break: break-all">{{ form.applyUser }}</span>
          </el-form-item>
          <el-form-item label="申请时间:">
            <span style="word-break: break-all">{{ form.applyTime }}</span>
          </el-form-item>
        </div>
        <div v-if="isturn != 1">
          <el-divider content-position="left">审核信息</el-divider>
          <el-form-item label="审核结果:">
            <span style="word-break: break-all">{{ form.checkResult }}</span>
          </el-form-item>
          <el-form-item label="驳回原因:" v-if="form.checkNote">
            <span style="word-break: break-all">{{ form.checkNote }}</span>
          </el-form-item>
          <el-form-item label="审核人:">
            <span style="word-break: break-all">{{ form.checkUser }}</span>
          </el-form-item>
          <el-form-item label="审核时间:">
            <span style="word-break: break-all">{{ form.checkTime }}</span>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer" v-if="isturn == 1">
        <!-- <el-button style="margin-right:10px" @click="dialogVisible = false">取消</el-button> -->
        <el-button style="margin-right: 10px" type="danger" @click="showReject"
          >驳回</el-button
        >
        <el-button type="success" @click="saveadd">通过</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  applyStatus,
  applyCensorship_page,
  applyCensorship_findById,
  applyCensorship_checkReject,
  applyCensorship_checkApproved,
} from "../../RequestPort/apply/approve.js";
import { selectDictListByParentCode } from "@/apis/commonType";
export default {
  components: {},
  name: "applyList",
  mixins: [],
  data() {
    return {
      search: { current: 1, size: 100, type: "" }, //搜索
      searchContractTypeChildIdArr: [],
      searchDatatime: [],
      total: 10,
      tableData: [{ enterWarehouseName: 1 }], //列表对象
      options: [
        { id: 1, name: "ceso" },
        {
          id: 2,
          name: "ceso111",
          children: [
            { id: 1, name: "ceso" },
            { id: 2, name: "ceso111" },
          ],
        },
      ],
      statusList: applyStatus(),
      form: { contractTypeChildIdArr: [], urlList: [] },

      dialogVisibleDesc: false,
      isCheckBok: true,
      isCheckBokS: true,
      parentName: "",
      showtype: false,
      teamList: [],
      isturn: 0,
    };
  },
  mounted() {
    this.getOption();
    this.getList();
    this.left = this.$refs.viewContract.offsetLeft + 90;
    this.style.left = this.left + "px";
    this.dom = this.$refs.viewBox;
    this.dom.addEventListener("scroll", this.handleFun);
  },
  beforeDestroy() {
    if (this.dom) this.dom.removeEventListener("scroll", this.handleFun);
  },
  methods: {
    handleFun() {
      this.style.left = this.left - this.dom.scrollLeft + "px";
    },
    getOption() {
      selectDictListByParentCode({ parentCode: "hetong", level: 2 }).then(
        (res) => {
          if (res.code == 200) {
            this.options = res.data;
          }
        }
      );
    },
    sort_change(column) {
      this.search.sortColumn = column.prop;
      if (column.order == "ascending") {
        this.search.sortKey = 0;
      } else {
        this.search.sortKey = 1;
      }
      this.getList(1);
    },
    clearType() {
      if (this.search.type) {
        this.search.contractTypeId = "";
        this.searchContractTypeChildIdArr = [];
        this.search.contractTypeChildId = "";
        this.isCheckBokS = true;
        this.search.type = "";
        this.showtype = false;
      }
    },
    changeChildSearch(val) {
      this.options.forEach((el) => {
        if (el.id == val) {
          if (el.children) {
            this.isCheckBokS = false;
          } else {
            this.isCheckBokS = true;
            this.searchContractTypeChildIdArr = [];
          }
          this.search.type = el.name;
          this.parentName = el.name;
        }
      });
    },
    changeLabelSearch(val) {
      var namekey = {};
      this.options.forEach((el) => {
        if (el.id == this.search.contractTypeId) {
          el.children.forEach((item) => {
            namekey[item.id] = item.name;
          });
          var name = [];
          val.forEach((items) => {
            name.push(namekey[items]);
          });
          let showname = name.toString();
          if (showname) {
            this.search.type = this.parentName + "(" + showname + ")";
          }
        }
      });
    },
    getList(current) {
      if (this.searchDatatime) {
        this.search.startTime = this.searchDatatime[0];
        this.search.endTime = this.searchDatatime[1];
      }
      this.search.contractTypeChildId = "";
      if (this.searchContractTypeChildIdArr.length > 0) {
        this.search.contractTypeChildId =
          this.searchContractTypeChildIdArr.toString();
      }
      if (current) {
        this.search.current = 1;
      }
      //查列表
      applyCensorship_page(this.search).then((res) => {
        this.tableData = res.data.records;
        this.total = res.data.total;
      });
    },
    handleSizeChange(val) {
      this.search.size = val;
      this.getList(1);
    },
    handleCurrentChange(val) {
      this.search.current = val;
      this.getList();
    },
    //重置查询条件
    reset() {
      (this.search = {
        current: 1,
        size: 100,
      }),
        (this.searchContractTypeChildIdArr = []);
      this.searchDatatime = [];
      this.getList(1);
    },

    clearnF() {
      this.form = {
        contractTypeChildIdArr: [],
        urlList: [],
        contractTypeId: "",
        note: "",
        teamId: "",
        id: 0,
        applyUser: "",
        applyTime: "",
        checkNote: "",
        checkResult: "",
        checkTime: "",
        checkUser: "",
      };
      this.repairBeforFilefileList = [];
    },
    desc(row) {
      this.isturn = 0;
      applyCensorship_findById({ id: row.id }).then((res) => {
        if (res.code == 200) {
          this.form = res.data;
          this.form.urlList;
          this.dialogVisibleDesc = true;
        }
      });
    },
    apply(row) {
      this.isturn = 1;
      applyCensorship_findById({ id: row.id }).then((res) => {
        if (res.code == 200) {
          this.form = res.data;
          this.form.urlList;
          this.dialogVisibleDesc = true;
        }
      });
    },
    showReject(row) {
      this.$prompt("", "驳回原因", {
        cancelButtonText: "取消",
        confirmButtonText: "提交",
        inputPattern: /^\S{1,100}$/,
        inputType: "textarea",
        inputErrorMessage: "请输入100字以内的驳回原因",
        inputPlaceholder: "请输入100字以内的驳回原因",
        closeOnClickModal: false,
      })
        .then(({ value }) => {
          applyCensorship_checkReject({
            checkNote: value,
            id: this.form.id,
          }).then((res) => {
            if (res.code == 200) {
              this.getList(1);
              this.clearnF();
              this.dialogVisibleDesc = false;
              this.$message.success("驳回成功");
            }
          });
        })
        .catch(() => {});
    },
    saveadd() {
      applyCensorship_checkApproved({ id: this.form.id }).then((res) => {
        if (res.code == 200) {
          this.getList(1);
          this.clearnF();
          this.dialogVisibleDesc = false;
          this.$message.success("通过成功");
        }
      });
    },
  },
};
</script>

<style  lang="less" scoped>
::v-deep .el-upload--picture-card {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
.searchType {
  width: 210px;
  box-shadow: 1px 1px 5px #888888;
  position: absolute;
  z-index: 100;
  background-color: #fff;
}
.SearchBar {
  /* border: 1px solid #ecedf1; */
  border-radius: 5px;
  margin: 10px;
  padding-top: 1%;
  background-color: #ffffff;
}
.SearchInput {
  width: 100%;
}
.SearchOptions {
  width: 100%;
}
.SearchBtn {
  margin-left: 3.7%;
}
.SearchBtn2 {
  margin-left: 20px;
}
.table {
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
  background-color: #ffffff;
}

.all {
  background-color: #f3f4f8;
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
}
.diaBtn {
  margin-right: 2%;
}
.diaInput {
  width: 82%;
}
.lab {
  margin-left: 20px;
}
.dialog_form {
  margin: 30px;
  padding: 10px;
}
.red {
  color: #f74b4b;
}
.radioList {
  display: block;
  margin: 10px 0px;
}
::v-deep.viewContract .el-form-item__content {
  position: initial;
}
</style>
