import axios from '@/common/js/request'

export function applyCensorship_page(data){
    const applyCensorship_page = axios({
        url: '/applyCensorship/page',
        method: 'get',
        params:data
    })
    return applyCensorship_page
}
export function applyStatus(){
    const list = [
        {value:2,label:'已送审'},
        {value:3,label:'已审核'},
        {value:4,label:'已驳回'},
    ]
    return list;
}
export function applyCensorship_findById(data){
    const applyCensorship_findById = axios({
        url: '/applyCensorship/findById',
        method: 'get',
        params:data
    })
    return applyCensorship_findById
}
export function applyCensorship_checkReject(data){
    const applyCensorship_checkReject = axios({
        url: '/applyCensorship/checkReject',
        method: 'post',
        data:data
    })
    return applyCensorship_checkReject
}
export function applyCensorship_checkApproved(data){
    const applyCensorship_checkApproved = axios({
        url: '/applyCensorship/checkApproved',
        method: 'get',
        params:data
    })
    return applyCensorship_checkApproved
}
